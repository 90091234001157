/*--------------------------------------------------------------
# Courses
--------------------------------------------------------------*/
.courses {
    overflow: hidden;
    .container {
        .courses-slider, .row {
            overflow: hidden;
            .swiper-wrapper, .col-md-3{
                .swiper-slide{
                    .course-wrap{
                        overflow: hidden;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        align-items: stretch;
                        justify-content: flex-start;
                        box-sizing: content-box;
                        border: 1px solid var(--oksijen-turuncu);
                        background-color: rgba(255, 255, 255, 1);
                        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
                        border-radius: 0.3rem;
                        height: 300px;
                        @media only screen and (max-width: 768px) {
                            height: 250px;
                        }
                        @media only screen and (max-width: 1200px) {
                            height: 280px;
                        }
                        .course-img{
                            display: block;
                            max-width: 100%;
                            height: 8.5rem;
                        }
                        .course-content {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: space-evenly;
                            padding: 0.5rem;
                            .course-item{
                                h3{
                                    font-size: 16px;
                                    font-weight: bold;
                                    color: #111;
                                    text-align: center;
                                    @media only screen and (max-width: 768px) {
                                        font-size: 12px;
                                    }
                                    @media only screen and (max-width: 1200px) {
                                        font-size: 14px;
                                    }
                                }
                                h4{
                                    font-size: 14px;
                                    color: #999;
                                }
                                p{
                                    font-style: italic;
                                    margin: 30px auto 15px auto;
                                }
                            }
                            .btn {
                                background-color: var(--oksijen-turuncu);
                                color: rgba(255, 255, 255, 1);
                                border-color: var(--oksijen-turuncu);
                                border-radius: 0.3rem;
                                &:hover {
                                    color: rgba(255, 255, 255, 1);
                                    background-color: var(--oksijen-turuncu-hover);
                                    border-color: var(--oksijen-turuncu-hover);
                                }
                            }
                        }
                    }
                }
            }
            .swiper-pagination{
                margin-top: 20px;
                position: relative;
                .swiper-pagination-bullet {
                    width: 12px;
                    height: 12px;
                    background-color: rgba(255, 255, 255, 1);
                    opacity: 1;
                    border: 1px solid var(--oksijen-turuncu);
                }
                .swiper-pagination-bullet-active {
                    background-color: var(--oksijen-turuncu);
                }
            }
        }
    }
}
